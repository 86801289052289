import algoliasearch from 'algoliasearch/lite';
import aa from 'search-insights';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { GtmEventType, mapAlgoliaSearchToGtmEvent } from '~/util/analytics';

export const searchClient = () => {
  const config = useRuntimeConfig();
  return algoliasearch(config?.public?.algoliaAppId || '', config?.public?.algoliaApiKey || '');
};

export function parseFilter(filterArr: string[], filterByStarter: string, currencyCode: string): string {
  const ccode = currencyCode?.toLowerCase() || '';
  const countryIdentifier = ccode !== 'usd' && ccode !== 'eur' && ccode !== '' ? `_${ccode}` : '';
  let filterPayload = '';
  // TODO: use locale or country code to properly filter currency
  filterArr.forEach((filter: string, i: number) => {
    const regMatch = /[ 0-9A-Za-z]/g;
    const filterSlug = (filter.replace(/-/g, 'TO').match(regMatch) || []).join('');
    const operand = i === 0 ? ' AND ' : ' OR ';
    const operandPlacer = filterByStarter?.length === 0 && operand === ' AND ' ? '' : operand;
    filterPayload += operandPlacer + `sell_price${countryIdentifier}:${filterSlug}`;
  });
  return filterPayload;
}

export function getAisIndex(): string {
  const config = useRuntimeConfig();
  const ctsApiEnv = config?.public?.environment;
  const regionLocaleProperties = useRegionLocaleProperties();
  const region = regionLocaleProperties.value?.regionCode || 'us';
  const envSlug = ctsApiEnv === 'production' ? '' : ctsApiEnv + '_';
  return `${region.toLowerCase()}_${envSlug}v2_product_index`;
}

export function trackAtcConversion({ objectIds = [] as string[], queryID = '' }) {
  try {
    if (!objectIds || objectIds?.length === 0) return;
    const config = useRuntimeConfig();
    const index = getAisIndex();
    aa('init', {
      appId: config?.public?.algoliaAppId || '',
      apiKey: config?.public?.algoliaApiKey || '',
      useCookie: true,
    });
    aa('convertedObjectIDs', {
      index,
      eventName: 'Add to Cart',
      objectIDs: objectIds,
      ...(queryID && { queryID }),
    });
  } catch (err) {
    // console.error(err);
  }
}

export function insightsMiddlewareFactory() {
  const allowedEvents = ['click', 'conversion', 'view'];
  const gtm = useGtm();

  return createInsightsMiddleware({
    insightsClient: aa,
    onEvent: (event, aaClient) => {
      const { insightsMethod, payload, eventType } = event;
      if (!allowedEvents.includes(eventType)) return;
      // ensure that we're tracking product results
      if (event?.eventType === 'view' && event?.hits?.[0]?.bcId) {
        const products = event?.hits as any[];
        const mapped = products.map((item: any, index: number) => {
          return { ...item, index };
        });
        gtm?.trackEvent(mapAlgoliaSearchToGtmEvent(GtmEventType.viewItemList, mapped));
      }

      // const { insightsMethod, payload, widgetType, eventType } = event;
      if (insightsMethod && aaClient) {
        aaClient(insightsMethod, payload);
      }
    },
    insightsInitParams: {
      useCookie: true,
    },
  });
}
